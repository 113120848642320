<template>
  <BaseContainer>
    <ExamList />
  </BaseContainer>
</template>

<script>
import ExamList from '@components/ClientExams/ExamList'

export default {
  page: {
    title: 'Tests'
  },

  components: {
    ExamList
  }
}
</script>
