<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Tests</BasePageHeading>
      </div>
      <BasePageActions>
        <CreateAssessmentButton />
      </BasePageActions>
    </BasePageHeader>

    <BasePageRefine>
      <SearchBox
        placeholder="Search tests"
        @search="searchExams($event)"
      />
      <!-- @TODO 2023-02-08 Remove whene no longer required -->
      <!-- <div class="w-1/3">
        <Select
          placeholder="Filter Tests"
          :options="filterOptions"
          @selected="filterBy($event)"
        >
        </Select>
      </div> -->
    </BasePageRefine>

    <BaseTabBar>
      <BaseTabs>
      </BaseTabs>
    </BaseTabBar>

    <ExamsLibrary
      :use-add-option="false"
      :search-query="searchQuery"
    />
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>Tests</BasePageHeading>
      </div>
    </BasePageHeader>
    <BaseLoader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
import CreateAssessmentButton from '@components/ClientAssessments/CreateAssessmentButton'
import ExamsLibrary from '@components/Exams/ExamsLibrary'
import SearchBox from '@components/TUI/SearchBox'
// import Select from '@components/TUI/Select'

import { mapGetters } from 'vuex'
import states from '@api/states'

export default {
  components: {
    CreateAssessmentButton,
    ExamsLibrary,
    SearchBox
    // @TODO 2023-02-08 Remove whene no longer required
    // Select
  },

  data() {
    return {
      states,

      examSuiteSlug: null,
      searchQuery: null,

      error: null
    }
  },

  computed: {
    ...mapGetters({
      organisationName: 'organisations/name',
      examSuiteOptions: 'clientExams/examSuiteOptions',
      customExams: 'clientExams/customExams'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }

      if (!this.examSuiteOptions.length === 0) {
        return states.LOADING
      }

      return states.READY
    },

    /**
     * @return {Array}
     */
    filterOptions() {
      const options = this.examSuiteOptions

      options.push({
        label: 'Custom Tests',
        value: 'custom-exams'
      })

      return options
    }
  },

  mounted() {
    this.fetchExams()
  },

  methods: {
    fetchExams() {
      this.$store.dispatch('clientExams/getClientExams', {
        examSuiteSlug: this.examSuiteSlug,
        searchQuery: this.searchQuery
      })
    },

    /**
     * Re-dispatch client exams based on the current filter
     *
     * @param {string} examSuiteSlug
     * @return void
     */
    filterBy(examSuiteSlug) {
      this.examSuiteSlug = examSuiteSlug
      this.fetchExams()
    },

    /**
     * Set search query and re-run fetch
     *
     * @param {string} query
     * @return void
     */
    searchExams(query) {
      this.searchQuery = query
      this.fetchExams()
    }
  }
}
</script>
